const enumSetores = () => {
    return {
        getSector: (setorId) => {
            const id = Number(setorId);
            switch (id) {
                case 1:
                    return 'TI';
                case 2:
                    return 'Departamento Pessoal';
                case 3:
                    return 'Auditoria';
                case 4:
                    return 'Administrativo';
                case 5:
                    return 'Contabil';
                case 6:
                    return 'Financeiro';
                case 7:
                    return 'Fiscal';
                case 8:
                    return 'Marketing';
                case 9:
                    return 'Comercial';
                case 10:
                    return 'Processos';
                default:
                    return 'Setor Desconhecido';
            }
        },
        getAllSectors: () => {
            return [
                {
                    id: 1,
                    name: 'TI',
                },
                {
                    id: 2,
                    name: 'Departamento Pessoal',
                },
                {
                    id: 3,
                    name: 'Auditoria',
                },
                {
                    id: 4,
                    name: 'Administrativo',
                },
                {
                    id: 5,
                    name: 'Contabil',
                },
                {
                    id: 6,
                    name: 'Financeiro',
                },
                {
                    id: 7,
                    name: 'Fiscal',
                },
                {
                    id: 8,
                    name: 'Marketing',
                },
                {
                    id: 9,
                    name: 'Comercial',
                },
                {
                    id: 10,
                    name: 'Processos',
                },
            ];
        },
    };
};

export default enumSetores;
